<template>
  <a-table
    bordered
    class="current-dealer"
    v-if="dealerId && dealer"
    :columns="columns"
    :data-source="dealers"
    :pagination="false"
  ></a-table>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
  name: "CurrentDealer",
  props: {
    dealerId: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.fetchDealerById(this.dealerId)
  },
  computed: {
    ...mapGetters({
      dealer: "dealers/currentDealer",
      columns: "dealers/columns",
    }),
    dealers() {
      return [this.dealer]
    },
  },
  methods: mapActions({
    fetchDealerById: "dealers/fetchDealerById",
  }),
}
</script>

<style lang="scss" scoped>
.current-dealer {
  margin: 20px 0;
}
</style>
