<template>
  <div class="dealer-buyers">
    <a-table
      bordered
      :columns="columns"
      :data-source="data"
      :pagination="{ hideOnSinglePage: true }"
    ></a-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
  name: "DealerBuyersTable",
  mounted() {
    this.fetchData()
  },
  computed: mapGetters({
    columns: "dealerBuyers/columns",
    data: "dealerBuyers/data",
  }),
  methods: mapActions({
    fetchData: "dealerBuyers/fetchData",
  }),
}
</script>

<style lang="scss">
.dealer-buyers {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 12px 5px !important;
  }

  // ?
  /*td {
		text-align: center;
	}*/
}
</style>

<style lang="scss" scoped></style>
