<template>
  <div class="dealer-buyers-page">
    <div class="back-wrapper">
      <router-link :to="`/dealer/${dealerId}`" class="back">Назад</router-link>
    </div>

    <CurrentDealer :dealer-id="dealerId" />
    <DealerBuyersTable />
  </div>
</template>

<script>
import DealerBuyersTable from "@/components/dealer-buyers-table/DealerBuyersTable"

import { mapGetters } from "vuex"
import CurrentDealer from "@/components/current-dealer/CurrentDealer"

export default {
  name: "DealerBuyers",
  components: {
    CurrentDealer,
    DealerBuyersTable,
  },
  computed: {
    ...mapGetters({
      columns: "dealers/columns",
      dealer: "dealers/currentDealer",
    }),
    dealerId() {
      return parseInt(this.$route.params.id)
    },
  },
}
</script>

<style lang="scss" scoped>
.dealer-buyers-page {
  .back-wrapper {
    display: flex;
    justify-content: flex-end;

    width: 100%;
  }
}
</style>
